import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Button, Modal } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import useDownloadReport from "./useDownloadReport";

const ExportButton = ({ isResponsive }: { isResponsive?: boolean }) => {
  const { downloadFinanceReport, downloadPatientsReport } = useDownloadReport();
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);
  const currentLocation = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);
    setStartDate(searchParams.get("startDate"));
    setEndDate(searchParams.get("endDate"));
  }, [currentLocation]);

  const handleDownloadReport = async () => {
    setLoading(true);

    const isFinances = currentLocation.pathname.includes("finances");
    let report = null;
    if(isFinances){
       report = await downloadFinanceReport(startDate, endDate);
    }
    else {
       report = await downloadPatientsReport();
    }
    setLoading(false);
    Modal.confirm({
      title: "Your report is ready!",
      okText: (
        <a href={URL.createObjectURL(report)} download={`${isFinances ? "Finances" : "Patients"}-Report.xlsx`}>
          Download
        </a>
      )
    });
  };

  return (
    <Button
      block={isResponsive}
      loading={loading}
      onClick={handleDownloadReport}
      icon={<DownloadOutlined />}
      style={{ margin: isResponsive ? "10px 0 0" : "0" }}
    >
      Export Report
    </Button>
  );
};

export default ExportButton;
