import { DeleteFilled, EditFilled, ExclamationCircleOutlined, InfoCircleFilled, PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Modal, DatePicker, Input, Space, Table, Tooltip, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import { endpoints } from "../../utils/apiClient";
import { ContentTitle } from "../ContentTitle";
import useUploadPdf from "../UploadRequisitionForm/useUploadPdf";
import useFetchLabData from "./useFetchLabData";
import moment from "moment";
import { HistoryVariablesProperties } from "./VariablesHistory";
import { formatDate } from "../../utils/utils";
import { Console } from "console";

interface ExtractVariablesProperties {
  lowerLimitValidation: number;
  upperLimitValidation: number;
  code: string;
  name: string;
  measurementUnit: string;
  variableValueType: number;
  description: string;
}

interface OptionsProperties {
  label: JSX.Element;
  value: string;
  data: ExtractVariablesProperties;
}

interface TableRecordsProperties {
  value: string | undefined;
  name: string | undefined;
  unit: string | undefined;
  key: number;
  data: ExtractVariablesProperties | undefined;
}

const VariablesTable = ({
  id,
  orderId
}: {
  id: string | undefined;
  orderId: string | undefined;
}) => {
  const [options, setOptions] = useState<OptionsProperties[]>([]);
  const [date, setDate] = useState<moment.Moment | Date | undefined | string>();
  const [dateMomentValue, setDateMomentValue] = useState<moment.Moment | null>(null);
  const [variable, setVariable] = useState<string | undefined>();
  const [selectedVariable, setSelectedVariable] = useState<string | undefined>();
  const [measurementUnit, setMeasurementUnit] = useState<string | undefined>();
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [currentSelection, setCurrentSelection] = useState<ExtractVariablesProperties | undefined>();
  const [tableData, setTableData] = useState<TableRecordsProperties[]>([]);
  const [indexOfItemToBeEdited, setIndexOfItemToBeEdited] = useState(-1);
  const [filelist, setFilelist] = useState<any[]>([]);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("Input a number!");
  const [lowerLimit, setLowerLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(999999);
  const [sampleCollectedAt, setSampleCollectedAt] = useState<moment.Moment | Date | undefined | string>();
  const [historyVariables, setHistoryVariables] = useState<HistoryVariablesProperties[]>([]);
  const { uploadData } = useUploadPdf();
  const { getLabVariables, postLabData, loading, setLoading } = useFetchLabData();

  const prepareData = (data: HistoryVariablesProperties) => {
    const dataArr = [];

    if (orderId) {
      "patientVariables" in data &&
        data.patientVariables &&
        data?.patientVariables.length &&
        dataArr.push({
          date: formatDate(data?.sampleCollectedAt as string),
          data: data?.patientVariables
        });
    } else {
      for (const prop in data.manualLabsInputs) {
        dataArr.push({
          date: formatDate(prop),
          data: data.manualLabsInputs[prop]
        });
      }
    }

    setHistoryVariables(dataArr);
  };

  useEffect(() => {
    getLabVariables(endpoints.extractVariables).then(response => {
      if (!response) return;
      setOptions(
        response.map(
          (el: ExtractVariablesProperties): OptionsProperties => ({
            label: AutoCompleteOptions(el.name, el.description),
            value: el.name,
            data: { ...el }
          })
        )
      );
    });

    if (orderId) {
      getLabVariables(endpoints.orderVariablesHistory, { patientOrderId: orderId }).then(
        (response: HistoryVariablesProperties) => {
          setSampleCollectedAt(response?.sampleCollectedAt);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    getLabVariables(endpoints.orderVariablesHistory, { patientOrderId: orderId }).then(response => prepareData(response));

  }, []);

  useEffect(() => {
    sampleCollectedAt && setDate(sampleCollectedAt);
  }, [sampleCollectedAt]);

  const AutoCompleteOptions = (name: string, description: string) => (
    <>
      <Typography.Paragraph strong style={{ marginBottom: "5px" }}>
        {name}
      </Typography.Paragraph>
      {description && (
        <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
          {description}
        </Typography.Text>
      )}
    </>
  );

  const handleDate = (date: moment.Moment | null, dateString: string) => {
    setDate(dateString);
    setDateMomentValue(date);
  };

  const handleFilter = (inputValue: string, { data }: any) => {
    const lowerCaseInputValue = inputValue.toLowerCase();
    return data.name.toLowerCase().includes(lowerCaseInputValue) || data.description?.toLowerCase().includes(lowerCaseInputValue);
  };

  const handleLimitValidation = (lowerLimitValidation: number, upperLimitValidation: number) => {
    setLowerLimit(lowerLimitValidation || 0);
    setUpperLimit(upperLimitValidation || 999999);
    setTooltipTitle(
      !isNaN(lowerLimitValidation) && !isNaN(upperLimitValidation)
        ? `Input a number between ${lowerLimitValidation} and ${upperLimitValidation}`
        : "Input a number!"
    );
  };

  const handleSelect = (value: string, option: any) => {
    setSelectedVariable(value);
    setMeasurementUnit(option?.data?.measurementUnit || "");
    setCurrentSelection(option?.data);

    handleLimitValidation(option.data.lowerLimitValidation, option.data.upperLimitValidation);
  };

  const handleInputChange = (e: any) => {
    const inputCurrentValue = isNaN(e?.target.value) ? inputValue : e?.target.value;
    setTooltipVisible(!inputCurrentValue || inputCurrentValue < lowerLimit || inputCurrentValue > upperLimit);
    setInputValue(inputCurrentValue);
  };

  const populateInputs = (inputValue = "", variable = "") => {
    setInputValue(inputValue);
    setVariable(variable);
    setSelectedVariable(variable);
  };

  const handleEdit = (record: TableRecordsProperties, index: number) => {
    populateInputs(record.value, record.name);
    setIndexOfItemToBeEdited(index);
    setCurrentSelection(record.data);
    if (record.data) {
      handleLimitValidation(record.data.lowerLimitValidation, record.data.upperLimitValidation);
    }
  };

  const handleCancelEdit = () => {
    populateInputs();
    setIndexOfItemToBeEdited(-1);
    setCurrentSelection(undefined);
  };

  const handleDelete = (index: number) => {
    setTableData(tableData.filter((el, key) => key !== index));
    setIndexOfItemToBeEdited(-1);
    populateInputs();
  };

  const tableColumns = [
    { title: ContentTitle("Variable Name"), dataIndex: "name", width: "40%" },
    { title: ContentTitle("Unit"), dataIndex: "unit", width: "15%" },
    { title: ContentTitle("Value"), dataIndex: "value", width: "15%" },
    {
      title: ContentTitle("Actions"),
      dataIndex: "actions",
      width: "15%",
      render: (text: string, record: TableRecordsProperties, index: number) => (
        <Space style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="Edit variable!">
            <Button type="text" onClick={handleEdit.bind(null, record, index)}>
              <EditFilled />
            </Button>
          </Tooltip>

          <Tooltip title="Remove variable!">
            <Button type="text" onClick={handleDelete.bind(null, index)}>
              <DeleteFilled />
            </Button>
          </Tooltip>
        </Space>
      )
    }
  ];

  const handleDataEntry = () => {
    if (!Boolean(inputValue && date && selectedVariable && Number(inputValue) >= lowerLimit && Number(inputValue) <= upperLimit)) {
      return;
    }

    const variable: TableRecordsProperties =
    {
      value: inputValue,
      data: currentSelection,
      name: selectedVariable,
      unit: measurementUnit,
      key: 0
    };

    if (checkIfVariableIsAlreadyAdded(variable) && indexOfItemToBeEdited === -1) {
      Modal.confirm({
        title: `You have already added this variable!`,
        icon: <ExclamationCircleOutlined />,
        okText: "Ok",
        okButtonProps: { danger: true },
        okCancel: false,
      });

      return;
    }

    if (indexOfItemToBeEdited >= 0) {
      setTableData(
        tableData.map((el, key) => {
          return key === indexOfItemToBeEdited
            ? {
              ...el,
              name: selectedVariable,
              unit: measurementUnit,
              value: inputValue,
              data: currentSelection,
              key
            }
            : el;
        })
      );

      setIndexOfItemToBeEdited(-1);
    } else {
      setTableData([
        ...tableData,
        {
          name: selectedVariable,
          unit: measurementUnit,
          value: inputValue,
          data: currentSelection,
          key: Date.now()
        }
      ]);
    }

    populateInputs();
    setMeasurementUnit(undefined);
    setTooltipTitle("Input a number!");
    setLowerLimit(0);
    setUpperLimit(999999);
  };

  const handleUpload = (currentFile: File) => {
    setFilelist([currentFile]);
    return false;
  };

  const handleCancel = () => {
    setFilelist([]);
  };

  const checkIfVariableIsAlreadyAdded = (selectedVariable: TableRecordsProperties) => {
    let exist = false;

    historyVariables.map((element) => {
      element.data?.map((e) => {
        if (e.name === selectedVariable.name) exist = true;
      });
    });

    tableData.map((element) => { if (element.name === selectedVariable.name) exist = true; });

    return exist;
  }

  const submitData = () => {
    setLoading(true);

    const varArr = [];
    varArr.push(
      postLabData(orderId ? endpoints.submitLabResults : endpoints.submitLabData, {
        patientOrderId: orderId,
        patientId: id,
        submitLabDto: {
          labTimestamp: date,
          values: tableData.map(variable => ({
            variableCode: variable?.data?.code,
            decimalValue: variable.value
          }))
        }
      })
    );

    if (orderId) {
      const formData = new FormData();
      filelist.forEach(file => {
        formData.append("file", file);
      });

      varArr.push(uploadData(endpoints.uploadLabResultsPdf, formData, { patientId: id, orderId }));
    }

    Promise.allSettled(varArr).then(values => {
      if (values.every(val => val.status === "fulfilled")) {
        setTableData([]);
        setFilelist([]);
      }
    });
  };

  const disabledDate = (current: any) => {
    return current && current > moment().endOf('day')
  }

  return (
    <>
      <Space className="variable-space" style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <Space size="large" className="variable-space">
          <DatePicker
            allowClear
            className="variable-datepicker"
            value={(sampleCollectedAt && moment(sampleCollectedAt)) || dateMomentValue}
            disabledDate={disabledDate}
            onChange={handleDate}
            format="MM/DD/YYYY"
            disabled={Boolean(tableData.length || sampleCollectedAt)}
          />

          <AutoComplete
            allowClear
            className="variable-autocomplete"
            defaultActiveFirstOption={false}
            value={variable}
            options={options}
            filterOption={handleFilter}
            onSelect={handleSelect}
            onChange={value => setVariable(value)}
          >
            <Input placeholder="Search for lab name" prefix={<SearchOutlined />} />
          </AutoComplete>
        </Space>

        <div style={{ position: "relative" }}>
          <Button
            type="primary"
            className="variable-submit-btn"
            disabled={!Boolean(orderId ? tableData.length && date && filelist.length : tableData.length && date)}
            onClick={submitData}
            loading={Boolean(tableData.length && loading)}
          >
            Submit {orderId ? "Order" : "Lab"} Results
          </Button>
          {(tableData.length && (
            <Typography.Text style={{ position: "absolute", top: "100%", right: "0", width: "365px", fontSize: "13px" }}>
              <InfoCircleFilled style={{ color: "#F48842", marginRight: "5px", fontSize: "16px" }} />
              Make sure that you hit “Submit” before you leave this page
            </Typography.Text>
          )) ||
            ""}
        </div>
      </Space>

      <Space size="large" className="variable-space">
        <Tooltip visible={tooltipVisible} title={tooltipTitle} placement="bottomLeft">
          <Input
            allowClear
            className="variable-input"
            addonAfter={measurementUnit}
            placeholder="Enter value"
            value={inputValue}
            onChange={handleInputChange}
            onPressEnter={handleDataEntry}
            onFocus={() => {
              (!inputValue || Number(inputValue) < lowerLimit || Number(inputValue) > upperLimit) && setTooltipVisible(true);
            }}
            onBlur={() => {
              setTooltipVisible(false);
            }}
          />
        </Tooltip>

        <Button
          type="primary"
          className="variable-add-btn"
          icon={<PlusOutlined />}
          onClick={handleDataEntry}
          disabled={
            !Boolean(
              inputValue && date && selectedVariable && Number(inputValue) >= lowerLimit && Number(inputValue) <= upperLimit
            )
          }
        >
          {!isNaN(indexOfItemToBeEdited) && indexOfItemToBeEdited >= 0 ? "Edit" : "Add Result"}
        </Button>

        {!isNaN(indexOfItemToBeEdited) && indexOfItemToBeEdited >= 0 ? <Button onClick={handleCancelEdit}>Cancel</Button> : ""}
      </Space>

      {orderId && (
        <Space size="large" className="variable-space" style={{ display: "flex", margin: "20px 0 0" }}>
          <Upload
            className="variable-upload-btn"
            beforeUpload={handleUpload}
            onRemove={handleCancel}
            fileList={filelist}
            accept=".pdf"
          >
            <Button className="variable-upload-btn" icon={<UploadOutlined />}>
              Select File
            </Button>
          </Upload>
        </Space>
      )}

      <div style={{ overflow: "auto" }}>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          size="small"
          bordered
          style={{ minWidth: "max-content", margin: "30px 0", padding: orderId ? "0" : "52px 0 0" }}
          pagination={{
            size: "small",
            hideOnSinglePage: true,
            showSizeChanger: false
          }}
        />
      </div>
    </>
  );
};

export default VariablesTable;
