import { UploadOutlined } from "@ant-design/icons";
import { Button, Card, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import { ContentTitle } from "../ContentTitle";
import { RouteContext } from "../Dashboard";
import useUploadPdf from "./useUploadPdf";

const UploadRequisitionForm = () => {
  const { id, orderId } = useParams<{ id: string; orderId: string }>();
  const [filelist, setFilelist] = useState<RcFile[]>([]);
  const { uploadData, loading } = useUploadPdf();
  const { setBreadcrumbItems } = useContext(RouteContext);
  const history = useHistory();

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Patients Data",
        path: "/patients"
      },
      {
        title: `Quest Diagnostics - No. ${orderId}`,
        path: `/order-profile/${orderId}`
      },
      {
        title: "Upload Requisition Form",
        path: `/requisition-form/${id}/${orderId}/`
      }
    ]);
  }, [id, orderId, setBreadcrumbItems]);

  const handleUpload = (currentFile: RcFile) => {
    setFilelist([currentFile]);
    return false;
  };

  const handleCancel = () => {
    setFilelist([]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    filelist.forEach(file => {
      formData.append("file", file);
    });

    const response = await uploadData(endpoints.uploadRequisitionPdf, formData, { patientId: id, orderId });
    if (response) {
      history.push(`/dashboard/order-profile/${orderId}`);
    }
  };

  return (
    <Card
      className="upload-requisition-form"
      title={ContentTitle("Upload Requisition Form")}
      size="small"
      actions={[
        <Button type="primary" key="btn" disabled={!filelist.length} onClick={handleSubmit} loading={loading}>
          Submit Requisition Form
        </Button>
      ]}
      style={{ maxWidth: "440px", margin: "0 30px" }}
      bodyStyle={{ minHeight: "150px" }}
    >
      <Upload beforeUpload={handleUpload} onRemove={handleCancel} fileList={filelist} accept=".pdf">
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
    </Card>
  );
};

export default UploadRequisitionForm;
