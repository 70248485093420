import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAdminsActions from "./useAdminsActions";
import { RouteContext } from "../Dashboard";
import { endpoints } from "../../utils/apiClient";
import { Row } from "antd";
import AccountInfo from "./AccountInfo";
import EditProfile from "./EditProfile";
import ChangePass from "./ChangePass";

const AdminProfile = () => {
  const { id } = useParams<{ id: string }>();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const [breadcrumbTitle, setBreadcrumTitle] = useState("Account Info");
  const { getAdminData, data, setData, loading } = useAdminsActions();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);

  useEffect(() => {
    getAdminData(endpoints.viewProfile, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Admins",
        path: "/admins"
      },
      {
        title: breadcrumbTitle,
        path: `/admin-profile/${id}`
      }
    ]);
    setCurrentKey("admins");
  }, [breadcrumbTitle, id, setBreadcrumbItems, setCurrentKey]);

  useEffect(() => {
    if (data?.firstName) {
      setBreadcrumTitle(`${data.firstName} ${data.lastName}`);
    }
  }, [data]);

  return (
    <Row gutter={15} style={{ margin: "0 30px" }}>
      <AccountInfo
        data={data}
        loading={loading}
        editProfile={{ showEditProfile, setShowEditProfile }}
        changePass={{ showChangePass, setShowChangePass }}
      />
      {showEditProfile && <EditProfile setShowEditProfile={setShowEditProfile} data={data} id={id} setData={setData} />}
      {showChangePass && <ChangePass setShowChangePass={setShowChangePass} id={id} />}
    </Row>
  );
};

export default AdminProfile;
