import { Card, Col, Row, Typography } from "antd";
import { PatientDataProperties } from ".";
import { formatDate } from "../../utils/utils";
import { ContentTitle } from "../ContentTitle";
import { deviceTypes } from "./deviceTypes";

const PatientData = ({ data: { dateOfBirth, email, firstName, lastName, gender, state, registerDate, emailConfirmed, authProvider, emailConfirmedAt, deviceType } }: { data: PatientDataProperties }) => {
  //birthday date process
  let birthDate: string;
  let joinedAt: string;
  birthDate = formatDate(dateOfBirth).toString()
  joinedAt = formatDate(registerDate, true).toString()

  const dataArr = [
    [{ title: "First Name", value: firstName ? firstName : "Unknown" }],
    [{ title: "Last Name", value: lastName ? lastName : "Unknown" }],
    [{ title: "Email", value: email }],
    [
      {
        title: "State",
        value: state,
      },
    ],
    [
      {
        title: "Sex",
        value: gender ? gender : "Unknown",
      },
      {
        title: "Date Of Birth",
        value: birthDate.substr(6, 4) < "1900" ? "-" : formatDate(dateOfBirth),
      },
    ],
    [
      {
        title: "Auth provider",
        value: authProvider,
      },
      {
        title: "Device Type",
        value: deviceTypes[deviceType],
      },
    ],
    [
      {
        title: "Register Date",
        value:
          joinedAt.substr(6, 4) < "1900" ? "-" : joinedAt,
      },
    ],
    [
      {
        title: "Email Confirmed",
        value: emailConfirmed ? (emailConfirmedAt
          ? "Yes, on " + formatDate(emailConfirmedAt, true)
          : "Yes") : "No"
      },
    ],
  ];

  return (
    <Col className="patient-data-card" xxl={{ span: 4 }} xl={{ span: 6 }} span="24" style={{ paddingLeft: "0" }}>
      <Card title={ContentTitle(`${firstName || ""} ${lastName || ""}`)} size="small" style={{ height: "100%" }}>
        {dataArr.map(
          (items, key) =>
          (<Row key={key}>
              {items.map((item, key) =>
                <Col span={24/items.length}>
                  <Typography.Text type="secondary">{item.title}</Typography.Text>
                  <Typography.Paragraph strong>{item.value}</Typography.Paragraph>
                </Col>)}
            </Row>))}
      </Card>
    </Col>
  );
};

export default PatientData;
