import { Alert, Button, Card, Col, Row, Table} from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { endpoints } from "../../../utils/apiClient";
import { ContentTitle } from "../../ContentTitle";
import { RouteContext } from "../../Dashboard";
import { OrderProfileProperties } from "../../OrderProfile";
import OrderDetails from "../../OrderProfile/OrderDetails";
import OrderFiles from "../../OrderProfile/OrderFiles";
import OrderStatus from "../../OrderProfile/OrderStatus";
import useFetchPatientProfile from "../../PatientProfile/useFetchPatientProfile";
import { useWarningDetails } from "./hooks";
import { CopyToClipboard } from "react-copy-to-clipboard"
import { clinicErrorSteps } from "./ClinicErrorSteps"
import SolveOrderAction from './SolveOrderAction'
import Spinner from "../../Spinner";

interface ClinicError {
  error: string;
  errorStep: number;
  id: number;
}

interface matchDataProperties {

  clinicCode: number,
  measurementUnit: string,
  value: number,
  variableCode: string,
  variableName: string,

}

interface unmatchDataProperties {

  orderCodes: string,
  measurementUnit: string,
  variableName: string,

}

export const WarningPage = () => {
  const { patientOrderId } = useParams<{ patientOrderId: string }>();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const { getConditions } = useFetchPatientProfile();
  const [orderData, setOrderData] = useState<OrderProfileProperties>({} as OrderProfileProperties);
  
  const details = useWarningDetails()
  const { warningData, setWarningData, loading, setLoading, getWarningDetails } = details

  const unmatchData = warningData.unmatchedVariable?.map((row: unmatchDataProperties, index: number) => ({
    measurementUnit: row.measurementUnit,
    orderCodes: row.orderCodes,
    variableName: row.variableName,
    key: index
  })
  )

  const matchData = warningData.matchedVariables?.map((row: matchDataProperties, index: number) => ({
    clinicCode: row.clinicCode,
    measurementUnit: row.measurementUnit,
    value: row.value,
    variableCode: row.variableCode,
    variableName: row.variableName,
    key: index
  })
  )

  const unmatchColumns = [
    {
      title: ContentTitle("Variable Name"),
      dataIndex: "variableName",
      width: "20%"
    },
    {
      title: ContentTitle("Var. Code"),
      dataIndex: "orderCodes",
      width: "10%"
    },
    {
      title: ContentTitle("Unit"),
      dataIndex: "measurementUnit",
      width: "10%"
    }
  ];

  const matchColumns = [
    {
      title: ContentTitle("Variable Name"),
      dataIndex: "variableName",
      width: "20%"
    },
    {
      title: ContentTitle("Var. Code"),
      dataIndex: "variableCode",
      width: "10%"
    },
    {
      title: ContentTitle("Clinic Code"),
      dataIndex: "clinicCode",
      width: "10%"
    },
    {
      title: ContentTitle("Value"),
      dataIndex: "value",
      width: "10%"
    },
    {
      title: ContentTitle("Unit"),
      dataIndex: "measurementUnit",
      width: "10%"
    }
  ];

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Admin Warnings",
        path: "/admin-warnings"
      },
      {
        title: `Quest Diagnostics - No. ${patientOrderId}`,
        path: `/warning-details/${patientOrderId}`
      }
    ]);
    setCurrentKey("admin-warnings");
  }, [patientOrderId, setBreadcrumbItems, setCurrentKey]);

  useEffect(() => {
    getWarningDetails(patientOrderId)
    getConditions(endpoints.patientsOrdersData, patientOrderId).then(responseData => {
      if (!responseData) return;
      setOrderData(responseData);
    })
  }, [])

  
  const checkMatchingErrors = () => {

    var clinicErrors = (warningData.clinicErrors as Array<ClinicError>);
    if (clinicErrors !== null)
      return clinicErrors?.filter(e => e.errorStep === 50)?.length > 0;

    return false;
  }

  const checkForRetrievingResultsErrors = () => {
    var clinicErrors = (warningData.clinicErrors as Array<ClinicError>);
    if (clinicErrors !== null)
      return clinicErrors?.filter(e => e.errorStep >= 30)?.length > 0;

    return false;
  }

  return (
    loading? 
    <Spinner></Spinner>
    :
    <>

     { checkForRetrievingResultsErrors() ? <SolveOrderAction setLoading = {setLoading} setWarningData = {setWarningData} patientOrderId = {parseInt(patientOrderId)}/> : null}
      <Row align="stretch" gutter={6} style={{ height: "100%", width: "100%" }}>
        <Col xxl={{ span: 16 }} xl={{ span: 16 }} lg={{ span: 16 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <Row align="stretch" gutter={15} style={{ margin: "30px 8px" }}>
            <OrderStatus data={orderData} />
            <OrderDetails data={orderData} />
            <OrderFiles data={orderData} />
          </Row>

          {checkMatchingErrors() ?
            (<div> <Row style={{ margin: "30px 15px", overflow: "auto" }}>
              <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <div style={{ marginBottom: 16 }}>
                  <b>Unmatched Variables</b>
                </div>
                <Table
                  bordered
                  columns={unmatchColumns}
                  dataSource={unmatchData}
                  pagination={{
                    size: "small",
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    pageSize: 10
                  }}
                  loading={loading}
                  size="small"
                  style={{ width: "100%", minWidth: "max-content" }}
                />
              </Col>
            </Row>
              <Row style={{ margin: "30px 15px 0", overflow: "auto" }}>
                <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <div style={{ marginBottom: 16 }}>
                    <b>Matched Variables</b>
                  </div>
                  <Table
                    bordered
                    columns={matchColumns}
                    dataSource={matchData}
                    pagination={{
                      size: "small",
                      hideOnSinglePage: true,
                      showSizeChanger: false,
                      pageSize: 10
                    }}
                    loading={loading}
                    size="small"
                    style={{ width: "100%", minWidth: "max-content" }}
                  />
                </Col>
              </Row></div>) 
              : 
              null
              }
          <Row>
            {(warningData.clinicErrors)?.map((clinicError: ClinicError) => (<Alert message={clinicErrorSteps[clinicError.errorStep]}
              description={clinicError.error}
              key={clinicError.id}
              type="warning"
              banner
              style={{ width: "100%", marginLeft: 32, marginRight: 32, marginBottom: 10 }}
            />))}
          </Row>
        </Col>
        <Col xxl={{ span: 8 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <Col style={{ width: "100%", height: "100vh", marginTop: "30px" }}>
            <Card
              title={ContentTitle("JSON")}
              extra={<CopyToClipboard text={warningData.clinicResultJSON}><Button>Copy</Button></CopyToClipboard>}
              size="small"
              style={{ width: "100%" }}
              bodyStyle={{ maxHeight: "100vh", overflow: "hidden", overflowY: "auto" }}
            >
              <code>
                {warningData.clinicResultJSON}
              </code>
            </Card>
          </Col>
        </Col>
      </Row>
    </>
  )
}


