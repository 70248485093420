import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import apiClient from "../../utils/apiClient";
import { heardAboutUsDbAnswerValues } from "./PatientFilteringOptions";

export interface Filters {
  currentPage: number;
  skip: number;
  userStatus: string | null;
  query: string | null;
  sortProperty: string | null;
  sortOrder: number;
  variableCode: string | null;
  answerIndex: string | null;
}

const useFetchPatients = (path: string) => {
  const currentLocation = useLocation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [patientsFilters, setPatientsFilters] = useState<Filters | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);

    setPatientsFilters({
      query: searchParams.get("searchFilter"),
      userStatus: searchParams.get("userStatus"),
      currentPage: searchParams.get("page") ? Number(searchParams.get("page")) : 1,
      skip: ((searchParams.get("page") ? Number(searchParams.get("page")) : 1) - 1) * (10 as number),
      sortProperty: searchParams.get("sortProperty"),
      sortOrder: Number(searchParams.get("sortOrder")),
      variableCode: searchParams.get("variableCode"),
      answerIndex: searchParams.get("answerIndex")
    })
  }, [currentLocation]);

  const getData = useCallback(async () => {
    if (patientsFilters === null)
      return;

    try {
      const filters = { skip: patientsFilters.skip, take: 10, [patientsFilters.sortProperty ?? "idSortingType"]: patientsFilters.sortOrder !== 0 ? patientsFilters.sortOrder : 2 };

      if (patientsFilters.query) {
        Object.assign(filters, { searchFilter: patientsFilters.query });
      }
      if (patientsFilters.userStatus) {
        Object.assign(filters, { userStatus: patientsFilters.userStatus });
      }
      if(patientsFilters.answerIndex && patientsFilters.variableCode){
        Object.assign(filters, { 
          possibleAnswerValue:{
              variableCode: patientsFilters.variableCode,
              answerIndex: patientsFilters.answerIndex,
              rawValue: heardAboutUsDbAnswerValues[Number(patientsFilters.answerIndex)].dbValue}});
      }
      const response = await apiClient.post(path, filters);
      setData(response.data.patients);
      setTotalItems(response.data.count);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [patientsFilters]);

  useEffect(() => {
    getData();
  }, [getData]);

  return { data, loading, totalItems, patientsFilters, setLoading };
};

export default useFetchPatients;
