import { message } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../utils/apiClient";

export interface AdminActionsProperties {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: number;
  isDeleted: boolean;
}

const useAdminsActions = () => {
  const history = useHistory();
  const [data, setData] = useState<AdminActionsProperties>();
  const [loading, setLoading] = useState(true);

  const getAdminData = (path: string, id?: string) => {
    const params = {};
    if (id) {
      Object.assign(params, { adminId: id });
    }
    apiClient
      .get(path, { params })
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(err => {
        if (err.response?.status === 403) {
          history.push("/dashboard/patients");
        }
      });
  };

  const postAdminData = (path: string, data: any) => {
    return new Promise((resolve, reject) => {
      apiClient.post(path, data).then(response => {
        if (response.status === 200) {
          resolve(true);
          message.success(`Account successfully ${path.includes("create-admin") ? "created" : "updated"}!`);
        } else {
          reject();
        }

        setLoading(false);
      });
    });
  };

  const deleteAdmin = async (path: string, id: number | undefined) => {
    try {
      await apiClient.delete(path, { params: { adminId: id } });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return { getAdminData, postAdminData, deleteAdmin, data, setData, loading };
};

export default useAdminsActions;
