import { Layout, Divider, Space, Button, Form } from "antd";
import SearchInput from "./SearchInput";
import DateFilter from "./DateFilter";
import ExportButton from "./ExportButton";
import UserMenu from "./UserMenu";
import Breadcrumbs from "./Breadcrumbs";
import { useContext, useEffect, useReducer, useState } from "react";
import { RouteContext } from "../Dashboard";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import LogFilters from "./LogFilters";
import ClearFiltersBtn from "./ClearFiltersBtn";
import filterReducer from "./filterReducer";
import OverviewDates from "./OverviewDates";
import FinancesFilters from "./FinancesFilters";
import PatientFilters from "./PatientFilters";

const Navbar = ({ isResponsive }: { isResponsive?: boolean }) => {
  const { Header } = Layout;
  const [form] = Form.useForm();
  const history = useHistory();
  const [state, dispatch] = useReducer(filterReducer, {});
  const { breadcrumbItems, filters, setFilters } = useContext(RouteContext);
  const [showSearchInput, setShowSearchInput] = useState(true);
  const [showFinancesFilters, setShowFinancesFilters] = useState(true);
  const [showPatientFilters, setShowPatientFilters] = useState(true);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showExportBtn, setShowExportBtn] = useState(false);
  const [showAddAdminBtn, setShowAddAdminBtn] = useState(false);
  const [showLogLevels, setShowLogLevels] = useState(false);
  const [showOverviewDates, setShowOverviewDates] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);

    !searchParams.toString() && form.resetFields();

    if (searchParams.has("patientEmail")) {
      form.setFieldsValue({ "patient-autocomplete": searchParams.get("patientEmail") });
    }

    const newFilters = {};

    for (const [key, value] of searchParams as any) {
      Object.assign(newFilters, { [key]: value });
    }

    setFilters(newFilters);

  }, [history.location.search]);

  useEffect(() => {
    if (!breadcrumbItems.length) return;

    const lastItem = breadcrumbItems[breadcrumbItems.length - 1];
    setShowSearchInput(
      lastItem.path.includes("finances") ||
      lastItem.path.includes("patients") ||
      lastItem.path.includes("admins") ||
      lastItem.path.includes("activity-logs")
    );
    setShowFinancesFilters(lastItem.path.includes("finances"))
    setShowPatientFilters(lastItem.path.includes("patients"))
    setShowDateFilter(lastItem.path.includes("finances") || lastItem.path.includes("activity-logs"));
    setShowExportBtn(lastItem.path.includes("finances") || lastItem.path.includes("patients"));
    setShowAddAdminBtn(lastItem.path.includes("admins"));
    setShowLogLevels(lastItem.path.includes("activity-logs"));
    setShowOverviewDates(lastItem.path.includes("overview"));
  }, [breadcrumbItems]);

  const props = { state, dispatch, history };

  return (
    <>
      <Header className="mhpheader" style={{ backgroundColor: "#fff" }}>
        <Form form={form} name="filter-controller" style={{ display: "inline-block", width: "100%" }}>
          <Space
            className="header-outer-space"
            style={{
              display: isResponsive ? "block" : "flex",
              justifyContent: showSearchInput || showOverviewDates ? "space-between" : "flex-end"
            }}
          >
            <Space size="middle" style={{ display: isResponsive ? "block" : "inline-flex" }}>
              {showSearchInput && <SearchInput {...props} />}

              {showLogLevels && <LogFilters isResponsive={isResponsive} {...props} />}

              {showFinancesFilters && <FinancesFilters isResponsive={false} {...props} />}

              {showPatientFilters && <PatientFilters isResponsive={false} {...props} />}

              {showOverviewDates && <OverviewDates {...props} />}
            </Space>

            <Space size="middle" style={{ display: isResponsive ? "block" : "inline-flex" }}>
              {(showLogLevels && filters && Object.values(filters).length && (
                <ClearFiltersBtn {...props} form={form} />
              )) ||
                ""}

              {showDateFilter && <DateFilter isResponsive={isResponsive} {...props} />}

              {showExportBtn && <ExportButton isResponsive={isResponsive} />}
              {showAddAdminBtn && (
                <Link to="/dashboard/add-new-admin">
                  <Button
                    block={isResponsive}
                    icon={<PlusOutlined />}
                    style={{ margin: isResponsive ? "10px 0 0" : "0" }}
                  >
                    Add New Admin
                  </Button>
                </Link>
              )}
              <UserMenu isResponsive={isResponsive} />
            </Space>
          </Space>
        </Form>
      </Header>

      {!isResponsive && (
        <>
          <Divider style={{ marginTop: "0" }} />
          <Breadcrumbs />
        </>
      )}
    </>
  );
};

export default Navbar;
