import { useState, useEffect, useContext, useReducer } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, Space, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import { DeleteTwoTone, EyeFilled } from "@ant-design/icons";
import useFetchPatients from "./useFetchPatients";
import { endpoints } from "../../utils/apiClient";
import { formatDate } from "../../utils/utils";
import { RouteContext } from "../Dashboard";
import { ContentTitle } from "../ContentTitle";
import { FilterValue, SortOrder } from "antd/lib/table/interface";
import DeveloperStatus from "./DeveloperStatus";
import filterReducer from "../Navbar/filterReducer";

export interface PatientsTableProperties {
  key: number;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  lastOrderDate: string | number | Date;
  createdAt: string | number | Date;
  dateOfBirth: string | number | Date;
  isDeleted: boolean;
  isDeveloper: boolean;
  emailConfirmed : boolean;
  emailConfirmedAt : string;
  authProvider : string
  heardAboutUsRawValue : string;
}

const PatientsTable = () => {
  const [tableRows, setTableRows] = useState<PatientsTableProperties[]>([]);
  const { data, loading, totalItems, patientsFilters, setLoading } =
    useFetchPatients(endpoints.patients);
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);

  const history = useHistory();
  const [state, dispatch] = useReducer(filterReducer, {});
  const currentLocation = useLocation();
  const searchParams = new URLSearchParams(currentLocation.search);

  const [sortProperty, setSortProperty] = useState(searchParams.get("sortProperty"));
  const [sortOrder, setSortOrder] = useState(searchParams.get("sortOrder"));

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Patients Data",
        path: "/patients"
      }
    ]);
    setCurrentKey("patients");
  }, [setBreadcrumbItems, setCurrentKey]);

  const tableColumns = [
    {
      title: ContentTitle("ID"),
      dataIndex: "id",
      defaultSortOrder: (!sortProperty) ? "descend" as SortOrder : ((sortProperty === "idSortingType") ? ((sortOrder === "1") ? "ascend" as SortOrder : "descend" as SortOrder) : null),
      sortDirections: ["ascend", "descend", "ascend"] as SortOrder[],
      sorter: () => {
        return 0;
      }
    },
    {
      title: ContentTitle("First Name"),
      dataIndex: "firstName",
      render: (text: string, record: PatientsTableProperties) => (
        <Space>
          {record.isDeleted && (
            <Tooltip title="User is deleted!">
              <DeleteTwoTone twoToneColor="#eb2f96" />
            </Tooltip>
          )}
          <Typography.Text style={{ width: 120 }}
            ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        </Space>
      )
    },
    {
      title: ContentTitle("Last name"),
      dataIndex: "lastName",
      render: (record: string) => (
        <Typography.Text style={{ width: 120 }}
          ellipsis={{ tooltip: record }}> {record ? (record) : ("Unknown")}</Typography.Text>
      )
    },
    {
      title: ContentTitle("Email"),
      dataIndex: "email"
    },
    {
      title: ContentTitle("Last Order date"),
      dataIndex: "lastOrderDate",
      defaultSortOrder: (sortProperty === "lastOrderDateSortingType") ? ((sortOrder === "1") ? "ascend" as SortOrder : "descend" as SortOrder) : null,

      render: (record: string) => (
        <>
          {record ? (record) : ("-")}
        </>
      ),
      sorter: () => {
        return 0;
      }
    },
    {
      title: ContentTitle("Register date"),
      dataIndex: "createdAt"
    },
    {
      title: ContentTitle("Email confirmed"),
      dataIndex: ["emailConfirmed", "emailConfirmedAt"],
      render: (text :any, row : any) => (
        <>
          {row["emailConfirmed"] ? (row["emailConfirmedAt"]
          ? "Yes, on " + formatDate(row["emailConfirmedAt"])
          : "Yes") : "No"}
        </>
      ),
    },
    {
      title: ContentTitle("Heard About Us"),
      dataIndex: "heardAboutUsRawValue"
    },
    {
      title: ContentTitle("Auth provider"),
      dataIndex: "authProvider"
    },
    {
      title: ContentTitle("Date of Birth"),
      dataIndex: "dateOfBirth",
      render: (record: string) => (
        <>
          {record.substr(6, 4) < "1900" ? ("-") : (record)}
        </>
      )
    },
    {
      title: ContentTitle("Actions"),
      dataIndex: "action",
      render: (text: string, record: PatientsTableProperties) => (
        <div>
          <DeveloperStatus patient={record} />
          <Link to={{ pathname: `/dashboard/patient-profile/${record.id}`, state: record }}>
            <Tooltip placement="left" title={`Go to ${record.lastName}'s profile`}>
              <Button type="text">
                <EyeFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
              </Button>
            </Tooltip>
          </Link>
        </div>
      )
    }
  ];

  const prepareTableData = (tableData: PatientsTableProperties[]) => {
    return tableData.map((item, key) => ({
      ...item,
      key,
      dateOfBirth: item.dateOfBirth && formatDate(item.dateOfBirth),
      createdAt: item.createdAt && formatDate(item.createdAt),
      lastOrderDate: item.lastOrderDate && formatDate(item.lastOrderDate)
    }));
  };

  useEffect(() => {
    setTableRows(prepareTableData(data));
  }, [data]);

  const handlePageUpdate = (page: number, pageSize: number | undefined) => {
    dispatch({ type: "UPDATE_FILTERS", paramKey: "page", paramValue: page.toString(), history });
    setLoading(true);
  };

  const sortableColumns: { [key: string]: string } = {
    id: "idSortingType",
    lastOrderDate: "lastOrderDateSortingType"
  };

  const handleChange = function (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    { field, order }: any,
    { action }: any
  ) {
    if (action !== "sort") return;

    setLoading(true);

    if (!order)
      dispatch({ type: "UPDATE_BULK_FILTERS", paramCollection: { sortProperty: "idSortingType", sortOrder: "2" }, history });
    else
      dispatch({ type: "UPDATE_BULK_FILTERS", paramCollection: { sortProperty: sortableColumns[field], sortOrder: (order === "ascend" ? 1 : 2).toString() }, history });
  };

  return (
    <div style={{ margin: "10px 30px", overflow: "auto" }}>
      <Table
        columns={tableColumns}
        dataSource={tableRows}
        pagination={{
          size: "small",
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: 10,
          current: patientsFilters ? patientsFilters.currentPage : 1,
          total: totalItems,
          showTotal: (total) => `${tableRows.length} of ${total} results`,
          onChange: handlePageUpdate
        }}
        style={{ minWidth: "max-content" }}
        loading={loading}
        size="small"
        onChange={handleChange}
      />
    </div>
  );
};

export default PatientsTable;
