import apiClient, { endpoints } from "../../utils/apiClient";

const useDownloadReport = () => {
  const downloadPatientsReport = async () => {
    let response;
  
    response = await apiClient.get(endpoints.patientsDownloadReport, {
        responseType: "blob"
      });

    return response.data;
  };

  const downloadFinanceReport = async (startDate: string | null, endDate: string | null) => {
    let filters = {};

    if (startDate && endDate) {
      filters = { startDate, endDate };
    }

    let response;

    response = await apiClient.post(endpoints.downloadReport, filters, {
      responseType: "blob"
    });

    return response.data;
  };

return { downloadPatientsReport, downloadFinanceReport };
}

export default useDownloadReport;
  
