import { Layout } from "antd";
import Brand from "./Brand";
import { useState, useEffect } from "react";
import SidebarList from "./SidebarList";
import { endpoints } from "../../utils/apiClient";
import useFetchWarningsCount from './useFetchWarningsCount';
import Spinner from "../Spinner";

const Sidebar = () => {
 const { Sider } = Layout;
 const { loading, setLoading, fetchWarningsCount } = useFetchWarningsCount();
 const [existClinicErrors, setExistClinicErrors] = useState(false);

  useEffect (() => {
    setLoading(true);

    fetchWarningsCount(endpoints["clinicErrorsCount"]).then(response => {
        if (response.clinicErrorsCount !== null && response.clinicErrorsCount !== undefined)
          if(response.clinicErrorsCount > 0)
            setExistClinicErrors(true);
      });
  }, []);

  return (
    <Sider
      breakpoint="lg"
      width="220"
      collapsedWidth="0"
      style={{ backgroundColor: "#294B6F" }}
      zeroWidthTriggerStyle={{ backgroundColor: "#294B6F" }}>
      <Brand />
      {loading ? <Spinner/> : <SidebarList existErrors = {existClinicErrors}/>}
      
    </Sider>
  );
};

export default Sidebar;
