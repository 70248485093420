import { Button, Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import { RouteContext } from "../Dashboard";
import Orders from "./Orders";
import PatientConditions from "./PatientConditions";
import PatientData from "./PatientData";
import Recommendations from "./Recommendations";
import Spinner from "../Spinner";
import Surveys from "./Surveys";
import useFetchPatientProfile from "./useFetchPatientProfile";
import { PlusOutlined } from "@ant-design/icons";
import ActivityLogsExcerpt from "./ActivityLogsExcerpt";
import { GeneralSubmit } from "./GeneralSubmit";
import { GeneticsSubmit } from "./GeneticsSubmit";
import { VitalSignsSubmit } from "./VitalSignsSubmit";
import styled from "styled-components";

export const GreyText = styled.div`
  color: #a8a8a8;
`;

export const BlackText = styled.div`
  color: #000000;
  font-weight: bold;
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export interface PatientDataProperties {
  dateOfBirth: string;
  email: string;
  firstName: string;
  gender: string;
  state: string;
  id: number;
  key: number;
  lastName: string;
  updatedAt: string;
  registerDate: string;
  screeningSurveySustained: boolean;
  emailConfirmed : boolean;
  authProvider : string;
  emailConfirmedAt : string;
  deviceType: number;
}

const PatientProfile = function () {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<PatientDataProperties>();
  const { getConditions, loading } = useFetchPatientProfile();
  const [patientData, setPatientData] = useState(state);
  const [patientName, setPatientName] = useState("");
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Patients Data",
        path: "/patients"
      },
      {
        title: patientName || "Patient Profile",
        path: `/patient-profile/${id}`
      }
    ]);
    setCurrentKey("patients");
  }, [id, patientName, setBreadcrumbItems, setCurrentKey]);

  useEffect(() => {
    getConditions(endpoints.patientDetails, id).then(responseData => {
      setPatientData(responseData);
      responseData.firstName && responseData.lastName && setPatientName(`${responseData.firstName} ${responseData.lastName}`);
    });
  }, [getConditions, id]);

  return (
    <>
      {loading || !patientData ? (
        <Spinner />
      ) : (
        <>
          {patientData.screeningSurveySustained && <Link to={`/dashboard/add-lab-input/${id}`}>
            <Button icon={<PlusOutlined />} style={{ position: "absolute", right: "0", bottom: "101%", margin: "0 30px" }}>
              Add Lab Input
            </Button>
          </Link>}

          <Row style={{ margin: "10px 30px" }} align="stretch" gutter={15}>
            <PatientData data={patientData} />
            <PatientConditions id={patientData.id} />
            <ActivityLogsExcerpt patientData={patientData} />
          </Row>

          <Row style={{ margin: "15px 30px" }} justify="space-between" >
            <Col className="surveys-orders-cards" xxl={{ span: 4 }} xl={{ span: 4 }} span="24" style={{ paddingLeft: "0" }}>
              <Surveys id={patientData.id} />
            </Col>
            <Col className="surveys-orders-cards" xxl={{ span: 5 }} xl={{ span: 5 }} span="24" style={{ paddingLeft: "0" }}>
              <Orders id={patientData.id} />
            </Col>
            <Col className="surveys-orders-cards" xxl={{ span: 5 }} xl={{ span: 5 }} span="24" style={{ paddingLeft: "0" }}>
              <GeneralSubmit id={patientData.id} dataType={50} />
            </Col>
            <Col className="surveys-orders-cards" xxl={{ span: 5 }} xl={{ span: 5 }} span="24" style={{ paddingLeft: "0" }}>
              <GeneticsSubmit id={patientData.id} dataType={51} />
            </Col>
            <Col className="surveys-orders-cards" xxl={{ span: 5 }} xl={{ span: 5 }} span="24" style={{ paddingLeft: "0" }}>
              <VitalSignsSubmit id={patientData.id} dataType={52} />
            </Col>
          </Row>
          <Row style={{ margin: "15px 30px" }} align="stretch">
            <Col className="recommendations-card" xxl={{ span: 24 }} xl={{ span: 24 }} span="24" style={{ paddingLeft: "0" }}>
              <Recommendations id={patientData.id} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default PatientProfile;
