import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import apiClient from "../../utils/apiClient";

interface GetAdminsProperties {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: number;
  isDeleted: boolean;
}
interface Filters {
  query: string | null;
}

const useFetchAdmins = (path: string) => {
  const [data, setData] = useState<GetAdminsProperties[]>([]);
  const [loading, setLoading] = useState(true);
  const [adminsFilters, setAdminsFilters] = useState<Filters | null>(null);
  const currentLocation = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);
    setAdminsFilters({ query: searchParams.get("searchFilter") });
  }, [currentLocation]);

  useEffect(() => {
    if (adminsFilters === null) return;

    const filters = {};
    if (adminsFilters.query) {
      Object.assign(filters, { searchFilter: adminsFilters.query });
    }
    apiClient
      .post(path, filters)
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(err => {
        if (err.response?.status === 403) {
          history.push("/dashboard/patients");
        }
      });
  }, [history, path, adminsFilters]);

  return { data, setData, loading };
};

export default useFetchAdmins;
