import { Card, Col, Row, Space, Tag, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { endpoints } from "../../utils/apiClient";
import { ContentTitle } from "../ContentTitle";
import Spinner from "../Spinner";
import useFetchPatientProfile from "./useFetchPatientProfile";

const recommendationStatus: { [key: number]: string } = {
  1: "Available",
  2: "Completed",
  3: "In Progress",
  4: "Invalidated",
};

const recommendationStatusColors: { [key: number]: string } = {
  1: "processing",
  2: "success",
  3: "danger",
  4: "success",
};

const regenerationTypes: { [key: number]: string } = {
  0: 'Now',
  2: 'Three Months',
  3: 'Six Months',
  4: 'One Year',
  5: 'Two Years',
  6: 'Three Years'
}

interface ResponseRecommendationsProperties {
  [key: string]: { actionGeneralType: number; title: string; name: string; status: number; regenerateType: number; isFutureShopAction: boolean };
}

interface RecommendationsProperties {
  title: string;
  data: { name: string, status: number, regenerateType: number, isFutureShopAction: boolean }[];
}

const Recommendations = ({ id }: { id: number }) => {
  const [recommendations, setRecommendations] = useState<RecommendationsProperties[]>([]);
  const { getConditions, loading } = useFetchPatientProfile();

  const prepareData = useCallback(data => {
    const dataArr = [];

    for (const item in data) {
      dataArr.push({ title: item, data: data[item] ?? [] });
    }
    return dataArr;

  }, []);

  useEffect(() => {
    getConditions(endpoints.patientsActions, id).then((responseData: ResponseRecommendationsProperties) => {
      console.log(responseData);
      setRecommendations(prepareData(responseData));
    });
  }, [getConditions, id, prepareData]);

  const recommendationsTitles: { [key: string]: string } = {
    lifestyleActions: "Lifestyle:",
    talkToYourDoctorActions: "Talk to your doctor:",
    shopActions: "Lab:"
  };

  return (
    <Card title={ContentTitle("Recommendations")} size="small" bodyStyle={{ padding: "0" }}>
      {loading ? (
        <Spinner spinnerStyles={{ width: "100%", margin: "10px 0 0", justifyContent: "center" }} />
      ) : recommendations ? (
        <Row className="recommendations-row" align="stretch" wrap={false} style={{ height: "100%" }}>
          {recommendations?.map((recommendation: RecommendationsProperties, key: number) => (
            <Col md={{ span: 8 }} span="10" key={key} style={{ width: "100%" }}>
              <Card size="small" style={{ height: "100%" }}>
                <Typography.Text strong>{recommendationsTitles[recommendation.title]}</Typography.Text>
                {recommendation.data.length ? (
                  <ul style={{ listStyle: "none", padding: 0 }}>
                    {recommendation.data.map((item, i: number) => (
                      <li key={i}>
                        <Space style={{ fontSize: "13px" }} align="center">
                          <div style={{ width: "4px", height: "4px", backgroundColor: "#000", borderRadius: "50%" }}></div>
                          {item.name}
                          <div style={{ display: "flex", justifySelf: "end" }}>
                            {recommendation.title === "shopActions" ?
                              (
                                <div>
                                  {!item.isFutureShopAction ? <Tag color={recommendationStatusColors[1]}>{recommendationStatus[1]}</Tag> : null}
                                  <Tag color={recommendationStatusColors[4]}>{regenerationTypes[item.regenerateType]}</Tag>
                                </div>
                              )
                              :
                              <Tag color={recommendationStatusColors[item.status]}>{recommendationStatus[item.status]}</Tag>}
                            {recommendation.title !== "shopActions" && <Tag color={recommendationStatusColors[item.status]}>{recommendationStatus[item.status]}</Tag>}
                          </div>
                        </Space>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography.Paragraph>No data available!</Typography.Paragraph>
                )}
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Typography.Paragraph>No data available!</Typography.Paragraph>
      )}
    </Card>
  );
};

export default Recommendations;
