import { Button, Card, Col, Form, Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { endpoints } from "../../utils/apiClient";
import { ContentTitle } from "../ContentTitle";
import { adminRoles } from "./adminRoles";
import useAdminsActions from "./useAdminsActions";
import { validatePhoneNumber } from "../AddNewAdmin/phoneValidator";

interface EditProfileFormProperties {
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
}

const EditProfile = ({ setShowEditProfile, data, id, setData }: any) => {
  const { postAdminData } = useAdminsActions();
  const [roleSelectOptions, setRoleSelectOptions] = useState<{ label: string; value: string }[]>([]);
  const handleEdit = async (values: EditProfileFormProperties) => {
    const response = await postAdminData(endpoints.editProfile, values);
    if (response) {
      setData(values);
    }
  };

  useEffect(() => {
    const selectOptions = [];
    for (const prop in adminRoles) {
      selectOptions.push({ label: adminRoles[prop], value: prop });
    }

    setRoleSelectOptions(selectOptions);
  }, []);

  return (
    <Col span="8">
      <Card title={ContentTitle("Edit Profile")} size="small">
        <Form
          name="editProfile"
          layout="vertical"
          requiredMark={false}
          onFinish={handleEdit}
          initialValues={{ ...data, role: `${data.role}`, id }}
        >
          <Form.Item hidden name="id" />

          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input your name!"
              },
              {
                min: 3,
                message: "Your name should have at least 3 characters!"
              }
            ]}
            style={{ marginBottom: "10px" }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input your name!"
              },
              {
                min: 3,
                message: "Your name should have at least 3 characters!"
              }
            ]}
            style={{ marginBottom: "10px" }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="phone"
            hasFeedback
            rules={[
              {
                type: "string",
              },
              () => ({
                validator(_, value) {
                  const validationResult = validatePhoneNumber(value);
                  if (validationResult.isSuccessful === true)
                    return Promise.resolve();
                  return Promise.reject(new Error(validationResult.message));
                }
              })
            ]}
            style={{ marginBottom: "10px" }}
          >
            <Input />
          </Form.Item>

          <Form.Item name="email" hidden />

          <Form.Item label="Role" name="role" rules={[{ required: true, message: "Please select a role!" }]}>
            <Select placeholder="Select from dropdown" allowClear options={roleSelectOptions} />
          </Form.Item>

          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={setShowEditProfile.bind(null, false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Col>
  );
};

export default EditProfile;
