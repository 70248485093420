import { Layout } from "antd";
import { createContext, useState } from "react";
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import FinancesTable from "../FinancesTable";
import PatientsTable from "../PatientsTable";
import Sidebar from "../Sidebar";
import MHPFooter from "./MHPFooter";
import PatientProfile from "../PatientProfile";
import SurveyProfile from "../SurveyProfile";
import OrderProfile from "../OrderProfile";
import ConditionProfile from "../ConditionProfile";
import Navbar from "../Navbar";
import AdminTable from "../AdminTable";
import AddNewAdmin from "../AddNewAdmin";
import AdminProfile from "../AdminProfile";
import MyAccount from "../MyAccount";
import useCheckTokenStatus from "../../utils/useCheckTokenStatus";
import useAccessPermissions from "../../utils/useAccessPermissions";
import AddLabInput from "../AddLabInput";
import UploadRequisitionForm from "../UploadRequisitionForm";
import ActivityLogs from "../ActivityLogs";
import ShopOffers from "../ShopOffers";
import OfferDetails from "../ShopOffers/OfferDetails";
import Overview from "../Overview";
import ResponsiveHeader from "../ResponsiveHeader";
import { AdminWarnings } from "../AdminWarnings";
import { WarningPage } from "../AdminWarnings/WarningPage";
import FailedPurchaseProfile from "../FailedPurchaseProfile";

export interface FilterProps {
  days: string;
  searchFilter: string;
  startDate: string;
  endDate: string;
  messageSequence: string;
  logLevel: string;
  statusLevel: string;
  patientId: string;
  adminId: string;
  orderStatus: string;
  transactionStatus: string;
  userStatus: string;
}

interface BreadcrumbProps {
  title: string;
  path: string;
}

interface RouteContextProps {
  filters: FilterProps | null;
  setFilters: Function;
  currentKey: string;
  setCurrentKey: Function;
  breadcrumbItems: BreadcrumbProps[];
  setBreadcrumbItems: Function;
}

export const RouteContext = createContext({} as RouteContextProps);

const DashboardPage = () => {
  const { Content } = Layout;
  const match = useRouteMatch();
  const [filters, setFilters] = useState(null);
  const [currentKey, setCurrentKey] = useState("none");
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const { hasMedicalAccess } = useAccessPermissions();

  useCheckTokenStatus();

  const componentsList = [
    {
      component: <FinancesTable />,
      path: `${match.path}/finances`
    },
    {
      component: <PatientsTable />,
      path: `${match.path}/patients`
    },
    {
      component: <PatientProfile />,
      path: `${match.path}/patient-profile/:id`
    },
    {
      component: <ConditionProfile />,
      path: `${match.path}/condition-profile/:conditionId`
    },
    {
      component: <SurveyProfile />,
      path: `${match.path}/survey-profile/:surveyId`
    },
    {
      component: <OrderProfile />,
      path: `${match.path}/order-profile/:orderId`
    },
    {
      component: <FailedPurchaseProfile />,
      path: `${match.path}/failed-purchase-profile/:transactionId`
    },
    {
      component: <AddLabInput />,
      path: `${match.path}/add-lab-input/:id/:orderId?`
    },
    {
      component: <UploadRequisitionForm />,
      path: `${match.path}/requisition-form/:id/:orderId`
    },
    {
      component: <AdminTable />,
      path: `${match.path}/admins`
    },
    {
      component: <AdminProfile />,
      path: `${match.path}/admin-profile/:id`
    },
    {
      component: <AddNewAdmin />,
      path: `${match.path}/add-new-admin`
    },
    {
      component: <MyAccount />,
      path: `${match.path}/my-account`
    },
    {
      component: <ActivityLogs />,
      path: `${match.path}/activity-logs`
    },
    {
      component: <ShopOffers />,
      path: `${match.path}/shop-offers`
    },
    {
      component: <OfferDetails />,
      path: `${match.path}/offer-details/:packageType`
    },
    {
      component: <Overview />,
      path: `${match.path}/overview`
    },
    {
      component: <AdminWarnings />,
      path: `${match.path}/admin-warnings`
    },
    {
      component: <WarningPage />,
      path: `${match.path}/warning-details/:patientOrderId`
    }
  ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
      <RouteContext.Provider value={{ breadcrumbItems, setBreadcrumbItems, filters, setFilters, currentKey, setCurrentKey }}>
        <Sidebar />

        <Layout style={{ backgroundColor: "#fff" }}>
          <Navbar />

          <Content style={{ position: "relative" }}>
            <Switch>
              {componentsList.map((item, key) => (
                <Route key={key} path={item.path}>
                  {item.component}
                </Route>
              ))}

              <Route path={match.path}>
                <Redirect
                  to={{
                    pathname: match.path + (hasMedicalAccess ? "/patients" : "/overview")
                  }}
                />
              </Route>
            </Switch>
          </Content>

          <MHPFooter />
        </Layout>

        <ResponsiveHeader />
      </RouteContext.Provider>
    </Layout>
  );
};

export default DashboardPage;
