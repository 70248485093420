import { useState, useEffect, useContext, useReducer } from "react";
import { Button, Table, Tag, Tooltip } from "antd";
import { Filters, useItems } from "./useItems";
import { EyeFilled } from "@ant-design/icons";
import { orderStatusRanking, orderStatusColors, transactionStatusRanking, transactionStatusColors, newOrderStatusRanking } from "./financesSortOptions";
import { formatDate } from "../../utils/utils";
import { RouteContext } from "../Dashboard";
import { Link, useHistory } from "react-router-dom";
import { ContentTitle } from "../ContentTitle";
import filterReducer from "../Navbar/filterReducer";

export interface FinancesTableProperties {
  hasAnOrderAssociatedWithBoston: boolean;
  lsClinicId: string;
  bostonHeartlabClinicId: string;
  orderId: number;
  labName: string;
  patientName: string;
  patientEmail: string;
  purchasedAt: string | number | Date;
  price: string;
  stripeId: string;
  orderStatus: number;
  transactionId: number;
  transactionStatus: number;
  [key: string]: any;
}

const FinancesTable = () => {
  const [tableRows, setTableRows] = useState<FinancesTableProperties[]>([]);
  const { items, loading, totalItems, setLoading, financesFilters } = useItems();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const history = useHistory();
  const [state, dispatch] = useReducer(filterReducer, {});

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Finances",
        path: "/finances"
      }
    ]);
    setCurrentKey("finances");
  }, [setBreadcrumbItems, setCurrentKey]);

  const tableColumns = [
    {
      title: ContentTitle("Order No."),
      dataIndex: "orderId"
    },
    {
      title: ContentTitle("LS Clinic Id"),
      dataIndex: "lsClinicId"
    },
    {
      title: ContentTitle("Tx Id"),
      dataIndex: "transactionId"
    },
    {
      title: ContentTitle("Lab Name"),
      dataIndex: "labName"
    },
    {
      title: ContentTitle("Patient Name"),
      dataIndex: "patientName"
    },
    {
      title: ContentTitle("Email"),
      dataIndex: "patientEmail"
    },
    {
      title: ContentTitle("Date"),
      dataIndex: "purchasedAt"
    },
    {
      title: ContentTitle("Price"),
      dataIndex: "price"
    },
    {
      title: ContentTitle("Stripe Id"),
      dataIndex: "stripeId"
    },
    {
      title: ContentTitle("Order Status"),
      dataIndex: "orderStatus",
      render: (status: number, record: FinancesTableProperties) => 
        status && 
          <Tag color={orderStatusColors[status]}>
            {record.hasAnOrderAssociatedWithBoston 
            ? newOrderStatusRanking[status] 
            : orderStatusRanking[status]}
          </Tag>
    },
    {
      title: ContentTitle("Transaction Status"),
      dataIndex: "transactionStatus",
      render: (status: number) => <Tag color={transactionStatusColors[status]}>{transactionStatusRanking[status]}</Tag>
    },
    {
      title: ContentTitle("Action"),
      dataIndex: "action",
      render: (text: string, record: FinancesTableProperties) => {
        return (
          !isNaN(record.orderId) ? (
            <Link to={`/dashboard/order-profile/${record.orderId}`}>
              <Tooltip placement="left" title={`Go to Order No.${record.orderId}`}>
                <Button type="text">
                  <EyeFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
                </Button>
              </Tooltip>
            </Link>
          ) : (
            <Link to={`/dashboard/failed-purchase-profile/${record.transactionId}`}>
              <Tooltip placement="left" title={`Go to Purchase No.${record.transactionId}`}>
                <Button type="text">
                  <EyeFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
                </Button>
              </Tooltip>
            </Link>
          )
        );
      }
    }
  ];

  useEffect(() => {
    console.log(items);
    
    setTableRows(
      items.map((item, key) => {
        Object.keys(item).forEach((k) => {
          item[k] = item[k] ?? '-'
        })
        
        if(item.hasAnOrderAssociatedWithBoston) {
          item.lsClinicId = item.bostonHeartlabClinicId
        }

        return {
          ...item,
          key,
          price: "$" + item.price,
          purchasedAt: formatDate(item.purchasedAt),
        }
      })
    );
  }, [items]);

  const handlePageUpdate = (page: number, pageSize: number | undefined) => {
    dispatch({ type: "UPDATE_FILTERS", paramKey: "page", paramValue: page.toString(), history });

    setLoading(true);
  };

  return (
    <div style={{ margin: "10px 30px", overflow: "auto" }}>
      <Table
        columns={tableColumns}
        dataSource={tableRows}
        pagination={{
          size: "small",
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: 10,
          current: financesFilters ? financesFilters.currentPage : 1,
          total: totalItems,
          showTotal: (total) => `${tableRows.length} of ${total} results`,
          onChange: handlePageUpdate
        }}
        style={{ minWidth: "max-content" }}
        loading={loading}
        size="small"
      />
    </div>
  );
};

export default FinancesTable;
