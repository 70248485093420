import { Link } from "react-router-dom";
import { Menu } from "antd";
import { BarChartOutlined, ContactsOutlined, HistoryOutlined, TagFilled, TeamOutlined, UnorderedListOutlined, WarningFilled } from "@ant-design/icons";
import { useContext } from "react";
import useAccessPermissions from "../../utils/useAccessPermissions";
import { RouteContext } from "../Dashboard";
interface SidebarListProps {
  existErrors: boolean
}
const SidebarList = (props: SidebarListProps) => {
  const { currentKey } = useContext(RouteContext);
  const { hasMedicalAccess, hasGeneralAccess } = useAccessPermissions();

  const menuData = [
    { text: "Overview", path: "overview", icon: <BarChartOutlined /> },
    { text: "Finances", path: "finances", icon: <UnorderedListOutlined /> },
    { text: "Patients Data", path: "patients", icon: <ContactsOutlined /> },
    { text: "Admins", path: "admins", icon: <TeamOutlined /> },
    { text: "Activity Logs", path: "activity-logs", icon: <HistoryOutlined /> },
    { text: "Shop Offers", path: "shop-offers", icon: <TagFilled /> },

    { text: "Admin Warnings", path: "admin-warnings", icon: props.existErrors ? <WarningFilled style={{ color: "orange" }} /> : <WarningFilled /> }
  ];

  const keyCollection: { [key: string]: string } = {
    none: "-1",
    overview: "0",
    finances: "1",
    patients: "2",
    admins: "3",
    "activity-logs": "4",
    "shop-offers": "5",
    "admin-warnings": "6"
  };

  const displayConditions = (path: string) => {
    if (hasMedicalAccess) {
      return ["finances", "admins", "activity-logs"].includes(path);
    }
    if (hasGeneralAccess) {
      return path === "admins";
    }
    return false;
  };

  const color = (key : any, currentKey : any) => {
    if(currentKey[0] == key)
      return "#F0F8FF";
    else
      return "";
  }

  return (
    <Menu selectedKeys={[keyCollection[currentKey]]} style={{ backgroundColor: "transparent", color: "#fff" }}>
      {menuData.map((item, key) => (
        <Menu.Item
          key={key}
          icon={item.icon}
          disabled={displayConditions(item.path)}
          style={{ paddingLeft: "20px", fontSize: "16px",  color: color(key, [keyCollection[currentKey]]) }}
        >
          <Link to={`/dashboard/${item.path}`}>{item.text}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default SidebarList;
